var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"canSelect":false,"navigation":"customer-requests","useServerPagination":"","headers":[
        { text: 'Company Name', value: 'buyer.companyName' },
        { text: 'Date', value: 'requestedOn', textFilter: 'toLongDateAndTime' },
        { text: 'Message', value: 'requesterMessage' },
        { text: 'Status', value: 'isApproved', display: true }],"loadingMsg":_vm.loadingMsg,"title":"Customer Requests"},scopedSlots:_vm._u([{key:"itemActions",fn:function(ref){
        var item = ref.item;
return [(item.isApproved == null)?_c('BT-Dialog',{attrs:{"item":item,"getOnOpen":_vm.getRequestOptions,"icon":"mdi-check","width":"500","label":"Approve"},on:{"ok":_vm.approveRequest},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
return [_c('BT-Select',{attrs:{"navigation":"supplier-price-tiers","itemValue":"id","itemText":"priceTierName","label":"Price Tier"},model:{value:(item.priceTierID),callback:function ($$v) {_vm.$set(item, "priceTierID", $$v)},expression:"item.priceTierID"}}),_c('div',[_vm._v("OR")]),_c('BT-Select',{attrs:{"navigation":"customer-templates","itemValue":"id","itemText":"templateName","label":"Customer Template"},model:{value:(item.templateID),callback:function ($$v) {_vm.$set(item, "templateID", $$v)},expression:"item.templateID"}})]}}],null,true)}):_vm._e(),(item.isApproved == null)?_c('v-btn',{attrs:{"title":"Reject Request","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.rejectRequest(item)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])],1):_vm._e()]}},{key:"isApproved",fn:function(ref){
        var item = ref.item;
return [(item.isApproved == null)?_c('span',[_vm._v("Awaiting Response")]):(item.isApproved === true)?_c('span',[_vm._v("Approved")]):(item.isApproved === false)?_c('span',[_vm._v("Rejected")]):_vm._e()]}},{key:"bottom",fn:function(){return [_c('BT-Snack',{model:{value:(_vm.msg),callback:function ($$v) {_vm.msg=$$v},expression:"msg"}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }